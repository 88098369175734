import React from 'react';
import DashboardPage from "./dashboard/DashboardPage";
import { ApolloProvider } from '@apollo/client';
import {apollo_client} from './stores/ApolloHelpper';
const App = () => {
    return (
        <ApolloProvider client={apollo_client}>
            <DashboardPage />
        </ApolloProvider>
    );
}

export default App;