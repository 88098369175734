import { split, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const host = `app.medinomi.zone`

const httpLink = new HttpLink({
    uri: `https://${host}/graphql`,
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: `wss://${host}/graphql`,
    })
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

export const apollo_client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
});