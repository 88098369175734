import { makeAutoObservable } from 'mobx';
import { apollo_client } from "../stores/ApolloHelpper";
import { gql } from "@apollo/client";

class BlockchainStore {
    blockchainData: any[] = [];
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async loadBlockchainData() {
        try {
            const result = await apollo_client.query({
                query: gql`
                    query {
                        readBlockChainRows
                    }`,
                fetchPolicy: 'network-only',
            });

            const rawData = result.data.readBlockChainRows; // Raw JSON string data
            const parsedData = JSON.parse(rawData); // Parse the JSON string into an object

            // 각 블록의 transactions 배열을 순회하면서 type1이 동일한 항목을 그룹화
            const combinedData = parsedData.chain.flatMap((block: any) => {
                const transactionsByType1: Record<string, any> = {};

                block.transactions.forEach((transaction: any) => {
                    if (!transactionsByType1[transaction.type1]) {
                        // 처음 등장한 transaction.type1이면 객체를 생성
                        transactionsByType1[transaction.type1] = {
                            id: transaction.type1,           // 트랜잭션 ID
                            block_number: block.index,       // 블록 번호
                            hash: block.previous_hash,       // 해당 블록의 previous_hash
                            transactions: [],                // 트랜잭션 설명 배열
                        };
                    }
                    // 트랜잭션 설명을 배열에 추가
                    transactionsByType1[transaction.type1].transactions.push(transaction.type2);
                });

                return Object.values(transactionsByType1);
            });

            this.blockchainData = combinedData.sort((a:any, b:any) => b.block_number - a.block_number);

            this.error = null; // 에러 초기화

            console.log('mapping',this.blockchainData)

            this.error = null; // Clear error if operation is successful

        } catch (error) {
            console.error("Failed to load blockchain data:", error);
            this.error = 'Failed to load blockchain data';
        }
    }
}

export const blockchainStore = new BlockchainStore();
