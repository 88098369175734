import { makeAutoObservable } from "mobx";
import { apollo_client } from "../stores/ApolloHelpper";
import { gql } from "@apollo/client";
import dayjs from "dayjs";

// ProfileEvent 데이터 타입 정의
export interface ProfileEventType {
    createdDate: string;
    uid: string;
    idNumber: string;
    eventType: string;
    eventDetail: string;
    name: string;
}

class ProfileEventStore {
    profileEventData: ProfileEventType[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    // profile_events 데이터를 불러오는 함수
    async loadProfileEventData() {
        try {
            const result = await apollo_client.query({
                query: gql`
                    query readProfileEvents {
                        profile_events {
                            createdDate
                            uid
                            idNumber
                            eventType
                            eventDetail
                            name
                            id
                        }
                    }
                `,
                fetchPolicy: 'network-only',
            });

            // 데이터를 MobX 상태로 업데이트
            this.profileEventData = result.data.profile_events.map((event: ProfileEventType) => ({
                ...event,
                createdDate: dayjs(event.createdDate).format("YYYY-MM-DD HH:mm:ss"),
            }));
        } catch (error) {
            console.error("Error loading profile event data:", error);
        }
    }
}

export const profileEventStore = new ProfileEventStore();