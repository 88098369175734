import React, { useEffect, useReducer } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Card } from 'antd';
import { profileEventStore, ProfileEventType } from '../stores/dashboard';
import { useSubscription } from '@apollo/client';
import { NEW_MESSAGE_SUBSCRIPTION } from '../stores/newmessage';
import {blockchainStore} from '../stores/blockchain'

// 초기 상태 정의
const initialState = {
    filteredData: [] as ProfileEventType[],
};

// 리듀서 함수 정의
const reducer = (state: typeof initialState, action: any) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                filteredData: action.payload,
            };
        default:
            return state;
    }
};

const DashboardPage = observer(() => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // 데이터를 로드하고 상태를 갱신하는 함수
    const loadData = async () => {
        await profileEventStore.loadProfileEventData();
        await blockchainStore.loadBlockchainData();
        dispatch({ type: 'SET_DATA', payload: profileEventStore.profileEventData });
    };

    // 구독 설정: 새로운 메시지를 받을 때 데이터를 다시 로드
    useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
        onSubscriptionData: () => {
            loadData(); // 구독 시 데이터를 다시 로드
            blockchainStore.loadBlockchainData();
        },
    });

    useEffect(() => {
        // 컴포넌트가 마운트될 때 데이터를 로드
        loadData();
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width:'20vw'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            width:'20vw'
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            hidden:true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => (
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '10vw',
                    }}
                    title={text}
                >
                    {text}
                </div>
            ),
        },
        {
            title: 'Event Type',
            dataIndex: 'eventType',
            key: 'eventType',
            width:'20vw'
        },
        {
            title: 'Event Detail',
            dataIndex: 'eventDetail',
            key: 'eventDetail',
        },
    ];

    const blockchainColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '15vw',
        },
        {
            title: 'Block Number',
            dataIndex: 'block_number',
            key: 'block_number',
            width: '15vw',
        },
        {
            title: 'Hash',
            dataIndex: 'hash',
            key: 'hash',
            width: '20vw',
            render: (text: string) => (
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '20vw',
                    }}
                    title={text}
                >
                    {text}
                </div>
            ),
        },
        {
            title: 'Transactions',
            dataIndex: 'transactions',
            key: 'transactions',
            width: '20vw',
            render: (text: string) => (
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '20vw',
                    }}
                    title={text}
                >
                    {text}
                </div>
            ),
        },
    ];


    // 데이터를 테이블에 렌더링
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '95vh'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
                <img src="./logo2.png" alt="right image" style={{height: 50, marginLeft:20, marginTop:10}}/>
                <img src="./logo1.png" alt="right image" style={{height: 50, marginRight: 20, marginTop: 10}}/>
            </div>

            <Card title="BITC " bordered={false} style={{width: '100%', height: '30%', overflow: 'auto'}}>
                <Table
                    columns={columns}
                    dataSource={state.filteredData.filter((item: any) => item.uid === 'bitc-page')}
                    rowKey={(record) => `${record.uid}-${record.createdDate}`}
                    pagination={{pageSize: 5}}
                />
            </Card>

            <Card title="BITC PoC" bordered={false} style={{width: '100%', height: '30%', overflow: 'auto'}}>
                <Table
                    columns={columns}
                    dataSource={state.filteredData.filter((item: any) => item.uid === 'bitc-poc-page')}
                    rowKey={(record) => `${record.uid}-${record.createdDate}`}
                    pagination={{pageSize: 5}}
                />
            </Card>

            <Card title="BITC Resident Companies" bordered={false}
                  style={{width: '100%', height: '30%', overflow: 'auto'}}>
                <Table
                    columns={columns}
                    dataSource={state.filteredData.filter((item: any) => item.uid === 'bitc-resident-page')}
                    rowKey={(record) => `${record.uid}-${record.createdDate}`}
                    pagination={{pageSize: 5}}
                />
            </Card>
            <Card title="BITC BlockChain" bordered={false} style={{width: '100%', height: '30%', overflow: 'auto'}}>
                <Table
                    columns={blockchainColumns}
                    dataSource={blockchainStore.blockchainData}
                    rowKey={"block_number"}
                    pagination={{pageSize: 5}}
                />
            </Card>
        </div>
    );
});

export default DashboardPage;